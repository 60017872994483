<template>
  <div
    id="builderArea"
    class="automations-layout"
  >
    <ResizeHandler
      resource-key="automations"
      slot-class="builder-navigation builder-navigation--left"
      left
      :default-width="256"
    >
      <StudioSidebar
        v-test-id="'automations-layout-sidebar'"
        resource="workflow"
      />
    </ResizeHandler>

    <div
      id="builderContent"
      class="automations-layout__content"
    >
      <slot />
    </div>

    <ResizeHandler
      resource-key="automations"
      slot-class="builder-navigation builder-navigation--right"
      right
      :default-width="296"
    >
      <slot name="options" />
    </ResizeHandler>
  </div>
</template>
<script>
import StudioSidebar from '~/components/studio/Sidebar';
import ResizeHandler from '~/components/resize-handler';

export default {
  name: 'AutomationsLayout',
  components: {
    ResizeHandler,
    StudioSidebar,
  },
};
</script>

<style lang="scss">
.automations-layout {
  max-height: calc(100vh - var(--z-topbar-height));
  height: 100%;
}

.automations-layout__content {
  padding-inline: 24px;
  padding-bottom: 25vh;
  overflow-y: auto;
}
</style>
